// Add more currencies here
// Always use this constant across codebase to specify transaction currency
export const TRANSACTION_CURRENCY = {
	ARISTO: "aristo",
	cUSD: "cUSD",
	NGN: "NGN",
	USD: "USD",
	USDT: "USDT",
	USDC: "USDC",
};
