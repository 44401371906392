import {
	child,
	equalTo,
	off,
	onChildAdded,
	orderByKey,
	push,
	query,
	ref as refD,
	setPriority,
	setWithPriority,
	update,
	get,
} from "firebase/database";
import { database } from "../firebase";

const useDatabase = () => {
	const saveStreamDocument = (
		roomKey,
		{ title, details, content, movieCID, userName, tags, imgLink, stream, price, currency },
		roomLength,
		writes,
		callback
	) => {
		const user = `Areaboi ${userName}`;
		console.log("Save Document", roomKey, title, details, content, movieCID, price, currency);

		const chatsRef = child(refD(database, "chat"), roomKey);

		const newPublish = push(chatsRef);
		const msgKey = newPublish.key;

		// Set default value for 'stream' if it's undefined
		const sanitizedStream = stream !== undefined ? stream : null;

		const data =
			roomKey === "Cinema"
				? {
						cache_version: 28,
						name: user,
						tit: title,
						title,
						text: details,
						video: movieCID,
						room_key: roomKey,
						tags,
						price,
						currency,
						stream: sanitizedStream, // Use sanitized stream value
					}
				: {
						cache_version: 28,
						name: user,
						tit: title,
						text: details,
						embedHTML: content,
						room_key: roomKey,
						tags,
						imgLink: imgLink || "",
						price,
						currency,
						stream: sanitizedStream, // Use sanitized stream value
					};

		const t = Date.now();
		console.log("New Publish: ", details);
		setWithPriority(newPublish, data, t);
		setPriority(chatsRef, t);
		update(child(chatsRef, msgKey), data);

		const orderedChatsRef = query(chatsRef, orderByKey(), equalTo(msgKey));
		onChildAdded(
			orderedChatsRef,
			(snapshot) => {
				off(orderedChatsRef);
				let dataSnap = snapshot.val();
				dataSnap.time = snapshot.exportVal()[".priority"] || snapshot[".priority"];
				update(child(chatsRef, msgKey), dataSnap);
			},
			{ onlyOnce: true }
		);

		if (roomLength === writes) {
			setTimeout(() => {
				callback();
			}, 2000);
		}
		console.log(msgKey);
		return msgKey;
	};

	const saveDocument = (
		roomKey,
		{ title, content, userName, mediaLink, tags, media, price, currency },
		roomLength,
		writes,
		callback
	) => {
		const user = `Areaboi ${userName}`;
		console.log("Save Document", roomKey, title, content, mediaLink, tags, media, price, currency);

		const chatsRef = child(refD(database, "chat"), roomKey);

		const newPublish = push(chatsRef);
		const msgKey = newPublish.key;

		const data =
			roomKey === "Cinema"
				? {
						cache_version: 28,
						name: user,
						tit: title,
						room_key: roomKey,
						tags,
						video: media,
						price,
						currency,
					}
				: {
						cache_version: 28,
						name: user,
						tit: title,
						embedHTML: content,
						room_key: roomKey,
						tags,
						imgLink: mediaLink || "",
						price,
						currency,
					};

		const t = Date.now();
		setWithPriority(newPublish, data, t);
		setPriority(chatsRef, t);
		update(child(chatsRef, msgKey), data);

		const orderedChatsRef = query(chatsRef, orderByKey(), equalTo(msgKey));
		onChildAdded(
			orderedChatsRef,
			(snapshot) => {
				off(orderedChatsRef);
				let dataSnap = snapshot.val();
				dataSnap.time = snapshot.exportVal()[".priority"] || snapshot[".priority"];
				update(child(chatsRef, msgKey), dataSnap);
			},
			{ onlyOnce: true }
		);

		if (roomLength === writes) {
			setTimeout(() => {
				callback();
			}, 2000);
		}
		console.log(msgKey);
		return msgKey;
	};

	const saveArcadeDocument = (
		roomKey,
		{ title, postType, userName, initialState, game, board, price, currency },
		roomLength,
		writes,
		callback
	) => {
		const user = `Areaboi ${userName}`;
		console.log(
			"Save Document",
			roomKey,
			title,
			postType,
			initialState,
			game,
			board,
			price,
			currency
		);

		const chatsRef = child(refD(database, "chat"), roomKey);

		const newPublish = push(chatsRef);
		const msgKey = newPublish.key;

		// // Set default value for 'stream' if it's undefined

		const data = {
			cache_version: 28,
			name: user,
			tit: title,
			type: postType,
			initialState,
			game,
			board,
			room_key: roomKey,
			price,
			currency,
		};

		const t = Date.now();
		setWithPriority(newPublish, data, t);
		setPriority(chatsRef, t);
		update(child(chatsRef, msgKey), data);

		const orderedChatsRef = query(chatsRef, orderByKey(), equalTo(msgKey));
		onChildAdded(
			orderedChatsRef,
			(snapshot) => {
				off(orderedChatsRef);
				let dataSnap = snapshot.val();
				dataSnap.time = snapshot.exportVal()[".priority"] || snapshot[".priority"];
				update(child(chatsRef, msgKey), dataSnap);
			},
			{ onlyOnce: true }
		);

		if (roomLength === writes) {
			setTimeout(() => {
				callback();
			}, 2000);
		}
		console.log(msgKey);
		return msgKey;
	};

	const pauseStream = async (stream) => {
		const streamSnapshot = await get(refD(database, `streams/${stream.id}`));
		const newStream = streamSnapshot.val();
		const updates = {};
		if (newStream.status === "active") {
			updates[`streams/${stream.id}/status`] = "paused";
			update(refD(database), updates);
			return true;
		} else if (newStream.status === "paused") {
			updates[`streams/${stream.id}/status`] = "active";
			update(refD(database), updates);
			return true;
		}
		return false;
	};

	const endStream = async (stream) => {
		const streamSnapshot = await get(refD(database, `streams/${stream.id}`));
		const newStream = streamSnapshot.val();
		const updates = {};
		if (newStream.status !== "created") {
			updates[`streams/${stream.id}/status`] = "ended";
			update(refD(database), updates);
			return true;
		}
		return false;
	};

	return { saveDocument, saveStreamDocument, saveArcadeDocument, pauseStream, endStream };
};

export default useDatabase;
