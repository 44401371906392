import React from "react";
import { TRANSACTION_CURRENCY } from "../../utils/transaction";

function DollarSvg({ currency, onClick }) {
	return (
		<svg
			style={{ cursor: "pointer" }}
			onClick={onClick}
			width="7"
			height="11"
			viewBox="0 0 7 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.94957 0V0.550272V1.55373H2.32002C0.895487 1.55373 0 2.61589 0 3.67773C0 4.73957 0.895487 5.80143 2.32002 5.80143H2.94957V7.84646H0.74661H0.196024V8.94732H0.74661H2.94957V9.95077V10.5011H4.05043V9.95077V8.94732H4.67967C6.10421 8.94732 7 7.88517 7 6.82331C7 5.76147 6.10421 4.69965 4.67967 4.69965H4.05043V2.65461H6.25308H6.80367V1.55373H6.25308H4.05043V0.550272V0H2.94957ZM2.32002 2.65461H2.94957V4.69965H2.32002C1.3849 4.69965 1.10086 4.18899 1.10086 3.67773C1.10086 3.16647 1.3849 2.65461 2.32002 2.65461ZM4.05043 5.80143H4.67967C5.6148 5.80143 5.89914 6.31206 5.89914 6.82331C5.89914 7.33458 5.6148 7.84646 4.67967 7.84646H4.05043V5.80143Z"
				fill="black"
				fillOpacity={currency === TRANSACTION_CURRENCY.USD ? "1" : "0.5"}
			/>
		</svg>
	);
}

export default DollarSvg;
