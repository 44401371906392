import React, { useState, useContext, useRef } from "react";
import ReactQuill from "react-quill";
import {
	Card,
	CardBody,
	Form,
	FormInput,
	ButtonGroup,
	Button,
	Row,
	Col,
	ModalHeader,
	FormSelect,
} from "shards-react";
import Modal from "../common/Modal";
import useDatabase from "../../hooks/useDatabase";
import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import { AuthContext } from "../../context/Context";
import MediaSelectorDropdown from "./MediaSelectorDropdown";
import FunctionInputUnit from "./FunctionInputUnit";
import BoardInputUnit from "./BoardInputUnit";
import { TRANSACTION_CURRENCY } from "../../utils/transaction";
import DollarSvg from "../../assets/svg/DollarSvg";
import NairaSvg from "../../assets/svg/NairaSvg";

const Quill = ReactQuill.Quill;
const Font = Quill.import("formats/font");
Font.whitelist = ["Barlow", "Cormorant", "RobotoMono", "BarlowBold", "CormorantBold"];
Quill.register(Font, true);

const modules = {
	toolbar: [
		[{ font: Font.whitelist }],
		[{ size: ["small", false, "large", "huge"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline", "strike"],
		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }],
		[{ direction: "rtl" }, { align: [] }],
		[{ color: [] }, { background: [] }],
		[{ script: "sub" }, { script: "super" }],
		["formula", "code-block", "blockquote"],
		["clean"],
	],
};

const formats = [
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"list",
	"bullet",
	"align",
	"color",
	"formula",
	"image",
	"video",
	"background",
	"script",
	"indent",
	"link",
	"header",
	"blockquote",
	"code-block",
	"direction",
];

const Editor = ({
	checkedRooms,
	checkedPublicChannel,
	selectedTags,
	setCheckedRooms,
	setSelectedTags,
	channelTag,
}) => {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [contentLength, setContentLength] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalOpenPublic, setModalOpenPublic] = useState(false);
	const [mediaLink, setMediaLink] = useState("");
	const [postType, setPostType] = useState("");
	const [boardLogicType, setBoardLogicType] = useState("effect");
	const [initialState, setInitialState] = useState({
		name: "",
		value: "",
	});

	const [media, setMedia] = useState("");
	const [gameFunctions, setGameFunctions] = useState([
		{
			logic: "",
			functionName: "",
			params: [""],
		},
	]);
	const [gameFunctionsNum, setGameFunctionsNum] = useState(1);
	const [boardFunctions, setBoardFunctions] = useState([
		{
			logic: "",
			params: [""],
		},
	]);
	const [boardFunctionNum, setBoardFunctionNum] = useState(1);
	const [price, setPrice] = useState(Number(0).toFixed(2));
	const [currency, setCurrency] = useState(TRANSACTION_CURRENCY.NGN);
	const quillRef = useRef(null);

	const db = useDatabase();
	const { user } = useContext(AuthContext);
	const userName = user ? user.profile.userName : "";

	const titleChange = ({ target: { value } }) => {
		setTitle(value);
	};

	const mediaLinkChange = ({ target: { value } }) => {
		setMediaLink(value);
	};

	const postTypeChange = ({ target: { value } }) => {
		setPostType(value);
	};

	const boardLogicTypeChange = ({ target: { value } }) => {
		setBoardLogicType(value);
		setBoardFunctions([
			{
				logic: "",
				params: [""],
			},
		]);
	};

	const initialStateChange = ({ target: { name, value } }) => {
		setInitialState((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	console.log(selectedTags);

	const [mediaType, setMediaType] = useState("image");
	const insertMedia = () => {
		if (!mediaLink) {
			console.error("Media link is empty or invalid");
			return;
		}

		try {
			const quill = quillRef.current.getEditor();
			const cursor = quill.getSelection().index;

			quill.insertEmbed(cursor, mediaType, mediaLink);
		} catch (error) {
			console.error("Error inserting image:", error);
		}
	};

	const editorChange = (myContent, delta, source, editor) => {
		setContent(myContent);
		setContentLength(editor.getLength());
	};

	const handleSave = async () => {
		if (
			contentLength <= 1 ||
			checkedRooms.length < 1 ||
			selectedTags.length < 1 ||
			mediaLink === ""
		) {
			return;
		}

		let writes = 0;
		for (const { key } of checkedRooms) {
			writes++;

			const messageKey = db.saveDocument(
				key,
				{ title, content, userName, mediaLink, tags: selectedTags, media, price, currency },
				checkedRooms.length,
				writes,
				() => {
					setTitle("");
					setContent("");
					setModalOpen(false);
					setCheckedRooms([]);
					setMediaLink("");
					setSelectedTags([]); // Clear tags after saving
					setMedia("");
					setPrice(Number(0).toFixed(2));
					setCurrency(TRANSACTION_CURRENCY.NGN);
				}
			);

			if (messageKey) {
				try {
					const res = await fetch(
						`https://postprocess-dxfmjwi24a-uc.a.run.app/chatUrl?messageId=${messageKey}&channelId=${key}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					console.log("process res", res);
				} catch (error) {
					console.error("Error fetching post-process URL:", error);
				}
			}
		}
	};

	const handleArcadeSave = async () => {
		if (title === "" || checkedRooms.length < 1) {
			return;
		}

		// game function
		let game = {};
		let otherIndex = 0;
		gameFunctions.forEach((fn) => {
			if (fn.functionName === "other") {
				otherIndex += 1;
				game[`${fn.functionName}${otherIndex}`] = fn;
			} else {
				game[fn.functionName] = fn;
			}
		});

		let board = {};
		boardFunctions.map((fn, index) => {
			board[`fn${index + 1}`] = fn;
		});

		let writes = 0;
		for (const { key } of checkedRooms) {
			writes++;

			const messageKey = db.saveArcadeDocument(
				key,
				{ title, postType, userName, initialState, game, board, price, currency },
				checkedRooms.length,
				writes,
				() => {
					setTitle("");
					setGameFunctions([
						{
							logic: "",
							functionName: "",
							params: [""],
						},
					]);
					setBoardFunctions([
						{
							logic: "",
							params: [""],
						},
					]);
					setPostType("");
					setInitialState({
						name: "",
						value: "",
					});
					setBoardLogicType("effect");
					setModalOpen(false);
					setCheckedRooms([]);
					setGameFunctionsNum(1);
					setBoardFunctionNum(1);
					setSelectedTags([]); // Clear tags after saving
					setPrice(Number(0).toFixed(2));
					setCurrency(TRANSACTION_CURRENCY.NGN);
				}
			);

			if (messageKey) {
				try {
					const res = await fetch(
						`https://postprocess-dxfmjwi24a-uc.a.run.app/chatUrl?messageId=${messageKey}&channelId=${key}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					console.log("process res", res);
				} catch (error) {
					console.error("Error fetching post-process URL:", error);
				}
			}
		}
	};

	const handlePublicSave = () => {
		if (contentLength <= 1 || checkedPublicChannel.length < 1) {
			return;
		}

		let writes = 0;
		checkedPublicChannel.forEach(({ channelId }) => {
			writes++;
			db.saveDocument(
				channelId,
				{ title, content, userName, mediaLink, tags: selectedTags },
				checkedPublicChannel.length,
				writes,
				() => {
					setTitle("");
					setContent("");
					setModalOpenPublic(false);
					setMediaLink("");
					setSelectedTags([]); // Clear tags after saving
				}
			);
		});
	};

	const selectedPublicChannel = () => (
		<ul className="list-unstyled list-group overflow-auto">
			{checkedPublicChannel &&
				checkedPublicChannel.map(({ channelName }, index) => (
					<li key={index} className="list-group-item">
						{channelName}
					</li>
				))}
		</ul>
	);

	const selectedRooms = () => (
		<ul className="list-unstyled list-group overflow-auto">
			{checkedRooms &&
				checkedRooms.map(({ title, tags }, index) => (
					<li key={index} className="list-group-item">
						<ModalHeader>{title}</ModalHeader>
						<div>
							{tags && tags.length
								? tags.map((tag) => (
										<div key={tag}>
											<input type="checkbox" checked={selectedTags.includes(tag)} />{" "}
											<span>{tag}</span>
										</div>
									))
								: null}
						</div>
					</li>
				))}
		</ul>
	);

	const handleAddFunction = (name) => {
		if (name === "game") {
			setGameFunctionsNum((prev) => prev + 1);
		} else if (name === "board") {
			setBoardFunctionNum((prev) => prev + 1);
		}
	};

	const handleRemoveFunction = (name) => {
		if (name === "game") {
			setGameFunctionsNum((prev) => prev - 1);
		} else if (name === "board") {
			setBoardFunctionNum((prev) => prev - 1);
		}
	};

	return (
		<>
			{checkedRooms.length === 0 && (
				<Modal
					modalOpen={modalOpen}
					modalTitle="Please Select a Channel!"
					affirmText="Okay"
					action={() => {
						setModalOpen(false);
					}}
					closeModal={() => setModalOpen(false)}
				>
					<h4 className="text-danger scriber-bold">You must select a channel to post into</h4>
				</Modal>
			)}
			{modalOpen && checkedRooms.length !== 0 && (
				<Modal
					modalOpen={modalOpen}
					modalTitle="Publish To This Channel"
					affirmText="Publish"
					action={channelTag !== "arcade" ? handleSave : handleArcadeSave}
					closeModal={() => setModalOpen(false)}
				>
					<div className="flex mb-3 justify-center">
						<div className="">PRICE :</div>
						<input
							type="number"
							step=".01"
							value={price}
							onChange={(e) => setPrice(e.target.value)}
						/>
						<div className="flex flex-column justify-between">
							<NairaSvg onClick={() => setCurrency(TRANSACTION_CURRENCY.NGN)} currency={currency} />
							<DollarSvg
								onClick={() => setCurrency(TRANSACTION_CURRENCY.USD)}
								currency={currency}
							/>
						</div>
					</div>
					{selectedRooms()}
				</Modal>
			)}

			{modalOpenPublic && (
				<Modal
					modalOpen={modalOpenPublic}
					modalTitle="Publish to this Channel"
					affirmText="Publish"
					action={handlePublicSave}
					closeModal={() => setModalOpenPublic(false)}
				>
					{selectedPublicChannel()}
				</Modal>
			)}

			<Card small className="rounded-0">
				<CardBody className=" blue-border">
					<Form className="add-new-post">
						<FormInput
							size="lg"
							className="mb-3"
							placeholder="TITLE YOUR POST"
							value={title}
							onChange={titleChange}
						/>
						{channelTag !== "arcade" ? (
							<>
								<Row className="mb-3 no-gutters d-flex">
									<Col className="flex-grow-1">
										<FormInput
											placeholder="Place your inline media link here"
											value={mediaLink}
											onChange={mediaLinkChange}
										/>
									</Col>
									<Col md={2} className="auto d-flex justify-content-end">
										<MediaSelectorDropdown mediaType={mediaType} setMediaType={setMediaType} />
									</Col>
									<Col md={2} className="auto d-flex justify-content-end">
										<Button onClick={insertMedia}>Insert</Button>
									</Col>
								</Row>
								<ReactQuill
									ref={quillRef}
									className="add-new-post__editor mb-1"
									placeholder="STYLE YOUR POST"
									modules={modules}
									formats={formats}
									value={content}
									onChange={editorChange}
								/>
							</>
						) : (
							<>
								<Row className="mb-3 no-gutters d-flex">
									<FormSelect value={postType} onChange={postTypeChange}>
										<option value="" disabled>
											-Select type-
										</option>
										<option value="game">Game</option>
										<option value="other">Other</option>
									</FormSelect>
								</Row>
								<FormInput
									size="lg"
									className="mb-3"
									placeholder="INITIAL STATE NAME"
									name="name"
									value={initialState.name}
									onChange={initialStateChange}
								/>
								<FormInput
									size="lg"
									className="mb-3"
									placeholder="INITIAL STATE (Array of objects)"
									name="value"
									value={initialState.value}
									onChange={initialStateChange}
								/>
								<div
									style={{
										border: "1px solid #ccc",
										borderRadius: "4px",
										padding: "0.25rem",
										marginBottom: "1rem",
									}}
								>
									<p className="m-2">Game Logic:</p>
									{Array.from({ length: gameFunctionsNum }, (_, index) => (
										<section className="m-2" key={index}>
											<FunctionInputUnit
												name="game"
												index={index + 1}
												setFunctionParams={setGameFunctions}
											/>
										</section>
									))}
									<section className="m-2 flex">
										<Button
											onClick={() => handleAddFunction("game")}
											squared
											className="rounded-0 btn-outline-primary blue-btn-outline px-3"
										>
											ADD
										</Button>
										<Button
											onClick={() => handleRemoveFunction("game")}
											squared
											className="rounded-0 btn- btn-outline-danger red-btn-outline px-3"
											disabled={gameFunctionsNum === 1 ? true : false}
										>
											REMOVE
										</Button>
									</section>
								</div>
								<div
									style={{
										border: "1px solid #ccc",
										borderRadius: "4px",
										padding: "0.25rem",
										marginBottom: "1rem",
									}}
								>
									<p className="m-2">Board Logic:</p>
									<section className="m-2">
										<FormSelect
											className="mb-3"
											value={boardLogicType}
											onChange={boardLogicTypeChange}
										>
											<option value="effect">has effect</option>
											<option value="noEffect">has no effect function</option>
										</FormSelect>
									</section>
									{boardLogicType === "effect" ? (
										<>
											{Array.from({ length: boardFunctionNum }, (_, index) => (
												<section className="m-2 mb-4" key={index}>
													<FunctionInputUnit
														name="board"
														index={index + 1}
														setFunctionParams={setBoardFunctions}
													/>
												</section>
											))}
											<section className="m-2 flex">
												<Button
													onClick={() => handleAddFunction("board")}
													squared
													className="rounded-0 btn-outline-primary blue-btn-outline px-3"
												>
													ADD
												</Button>
												<Button
													onClick={() => handleRemoveFunction("board")}
													squared
													className="rounded-0 btn- btn-outline-danger red-btn-outline px-3"
													disabled={boardFunctionNum === 1 ? true : false}
												>
													REMOVE
												</Button>
											</section>
										</>
									) : (
										<section className="m-2">
											<BoardInputUnit setFunctionParams={setBoardFunctions} />
										</section>
									)}
								</div>
							</>
						)}
					</Form>
				</CardBody>
			</Card>

			<div className="px-5 mx-5">
				<Row className="justify-content-center">
					<Col className="text-center">
						<ButtonGroup className=" mb-2">
							<Button
								onClick={() => setModalOpen(true)}
								className="rounded-0 blue-banner scriber-bold px-5"
							>
								PUBLISH
							</Button>
						</ButtonGroup>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Editor;
